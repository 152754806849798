<template>
  <div class="wrapper fall-workshop">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/workshop/umcbg.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">22nd Annual UMC Conference</h2>
            <h4>
              <strong>
                January 24 & 25, 2025<br />The Marriott University Park<br />Salt
                Lake City</strong
              >
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              The Art and Science of...
            </h3>
            <hr />
            <div class="row">
              <div class="col-md-8 ml-auto mr-auto">
                <h4>Keynote Speaker<br>Dr. Susan Zoll</br></h4>
                <h6>Author of Powerful Literacy in the Montessori Classroom: Aligning Reading, Research, and Practice</h6>
                <p>
                  Dr. Zoll’s work includes being a co-chair on the research committee for the American Montessori Society (AMS), a research consultant for AMS, a teacher trainer, head of school, director of admissions Montessori teacher, and author. She will share her research on the Science of Reading to introduce our day to examining the science of...everything!
                </p>
              </div>
              <div class="col-md-4 ml-auto mr-auto">
                <img src="img/zoll.jpeg" class="img img-raised" />
              </div>
            </div>
            <hr />
            <div class="col-md-12 ml-auto mr-auto">
              <h4><strong>Friday, January 24th</strong></h4>
              <p>
                <strong>1:00-3:00pm: </strong>Self-directed School Tour: Pinyon Montessori (515 E 100 S Suite 100, Salt Lake City, UT 84102)<br />
                <strong>4:00-5:00pm:</strong> Check-in, Appy Hour in the Atrium at the Marriott University Park<br />
                <strong>5:00-6:00pm:</strong> Opening Ceremony: The Art and Science of...
              </p>
            </div>
            <div class="col-md-12 ml-auto mr-auto">
              <h4><strong>Saturday, January 25th</strong></h4>
              <p>
                <strong>8:00-8:45am: </strong>Breakfast, Poster Sessions and Community Slideshow<br />
                <strong>8:45-9:30am: </strong>Welcome<br />
                <strong>9:30-10:30am: </strong>Keynote by Dr. Susan Zoll<br />
                <strong>10:45am-4:15pm: </strong>Workshops, Lunch Included, Door Prize
                Drawing, Montessori Mall<br />
                <strong>4:15-4:45pm: </strong>Closing Ceremony<br />
              </p>
            </div>
            <div class="col-md-12 ml-auto mr-auto">
              <p><i>Bring a door prize from your school or business!</i></p>
            </div>
            <hr />
            <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="downloads/2025 UMC Conference.pdf"
              ><strong>Conference Flyer</strong></a
            >
            <!-- <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="/umc-info
"
              ><strong>Presenter Bios</strong></a
            > -->
          </div>

          <div class="col-md-4 ml-auto mr-auto">
            <h3 class="title">Registration Information</h3>
            <p><b>Early Bird Registration:</b> $185/person until Dec. 6th</p>
            <p><b>Late Registration:</b> $200/person after Dec. 6th<br /></p>
            <p><b>Final Registration Deadline January 10th</b></p>
            <p>
              <b
                >Conference goers can get a special rate of $139 a
                night at the Salt Lake City Marriott University Park.<a
                  href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1708711569653&key=GRP&app=resvlink"
                  target="_blank"
                  >
To reserve at that rate click this link.</a
                ></b
              >
            </p>
            <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="https://form.jotform.com/242497005225150"
              ><strong>Register Here!</strong></a
            >
            <p>
              Our volunteers are working to bring you a professional conference
              at the lowest possible cost. Therefore we cannot offer refunds.
            </p>
          </div>
          <div class="col-md-12 ml-auto mr-auto">
            <hr />
            <p>
              This event is open to UMC members only. Visit
              <a
                href="https://utahmontessori.org/become-a-member"
                target="_blank"
                >Become a Member</a
              >. Contact leah@soaringwings.org about advertising as a vendor
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, InfoSection, FormGroupInput } from "@/components";

export default {
  name: "umc-conference",
  bodyClass: "umc-conference",
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },

  data() {
    return {};
  },
  metaInfo: {
    title: "Utah Montessori - UMC Conference",
    meta: [{ name: "description", content: "Sign up for the UMC Conference." }],
  },
};
</script>
<style></style>
